$info_my: #00bcd4;
$orange_my: #ff9800;
$green_my: #4caf50;

//Высота логотипа:
$navbar-item-img-max-height: 2.75rem;

//Расстояние иконок до меню:
a.navbar-item>.icon:not(:last-child) {
  margin-left: -0.25em;
  margin-right: .25em;
}

.bd-focus {
  margin: 6rem auto 0;
  max-width: 1080px;
}

.has-text-info_my {
  color: $info_my !important;
}

.has-text-orangemy {
  color: $orange_my !important;
}

.has-text-green_my {
  color: $green_my !important;
}

.card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  background-color: #fff;
}

.bd-index-header{
  text-align:center
}

.bd-index-header a,.bd-index-header strong {
  transition-duration:86ms;transition-property:color
}

.bd-index-header a {
  color: currentColor
}

.bd-index-header a:hover {
  //color:#3273dc
  color: $blue;
}

.bd-index-header a:hover strong {
  //color:#3273dc
  color: $blue;
}
.bd-index-header strong {
  font-weight:600
}

.bd-index-header .title strong {
  //color:#00d1b2
  color: $green_my;
}

.bd-index-header .subtitle {
  color:#b5b5b5!important
}

.bd-index-header .subtitle strong {
  color:currentColor
}

// .bd-index-header:not(:last-child) {
//   margin-bottom:4rem
// }

.subtitle.is-4 {
  font-size: 1.5rem;
}

//parallax на главной странице
.main-bg-img {
  background: url('../images/home/armo_karkasi_kran.jpg');
  // height: 685px;
  // center center;
  background-size:cover;
  background-attachment: fixed;
}

//Нам доверяют:
.footer-support {
  padding: 3rem;
  text-align: center;
}
.footer-support .footer-title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}

.footer-title {
  color: #363636;
  font-size: 1.25rem;
  line-height: 1.25;
  margin-bottom: .5rem;
  transition-duration: 86ms;
  transition-property: color;
}

.footer-clients {
  justify-content: center;
}

.footer-client {
  //width: 280px;
  flex: none;
}

// @media screen and (min-width:769px),
// print {
//   .footer-client {
//     width: 280px
//   }
// }

.footer-support {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1.5rem 1.5rem -1.25rem rgba(10, 10, 10, .05);
  display: block;
}

.footer-client-title {
  color: #b5b5b5;
  margin-bottom: 1.5rem;
}

.footer-client-title strong {
  color: #363636;
}

.footer-client-action{
  
  align-items: center;
  display: flex;
  justify-content: center;
}

//Footer
.footer-link-title {
  //color: #363636;
  color: $white;
  font-size: 1.25rem;
  //font-weight: 600
}

.footer-link {
  margin-top: .5rem;
  color: #a7a7a7;
}
.footer-link a {
  color: #a7a7a7;
}

.footer-link a:hover {
  color: $white;
}

.footer-link.is-more {
  font-size: .875rem
}

//.footer-link.is-more a:not(:hover) {
  //  color: $dark;
  //}
  
.is-more a:not(:hover) {
  color: $white;
}

.hero.is-dark {
  background-color: $dark_my;
  color: whitesmoke;
}

//aside nav:
//$menu-item-hover-color: $green_my;
//$menu-item-hover-background-color: $primary;
//Металлоизделия:
.bd-header .subtitle {
  color: #7a7a7a;
}

.pd-content {
  padding-left: 20px; 
}

.side-menu-prod {
  background:#345;
  color:#FFF;
  height:2em;
  padding:.5em;
  position:absolute;
  top:150px;
  width:100%;
}

.fixednav {
  position:fixed;
  top:0;
}

/* scroll top button */
.scrolltop {
	display:none;
	width:100%;
	margin:0 auto;
	position:fixed;
	bottom:20px;
  right:10px;
}
.scroll {
	position:absolute;
	right:15px;
	bottom:20px;
  // padding: 10px 15px 10px 15px;
	text-align: center;
	margin: 0 0 0 0;
  cursor:pointer;
  // background-color: $info;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s; 		
}
.scroll:hover {
  // background:rgba(178,178,178,1.0);
  // border-radius: 20px;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s; 		
}
// .scroll:hover .fa {
// 	padding-top:-10px;
// }
// .scroll .fa {
// 	font-size:10px;
// 	margin-top:-5px;
// 	margin-left:1px;
// 	transition: 0.5s;
// 	-moz-transition: 0.5s;
// 	-webkit-transition: 0.5s;
// 	-o-transition: 0.5s; 	
// }

/* TABLES */

.center-table th {
  text-align: center;
  vertical-align: middle;
}

.center-table td {
  text-align: center;
  vertical-align: middle;
}

.center-table .maincell {
  background-color: hsl(0, 0%, 93%);
}

// a.navbar-item.is-active {
//   background-color: #ff9800;
//   color: #0a0a0a;
// }

// a.navbar-item,
// .navbar-link {
//   cursor: pointer;
//   &:hover,
//   &.is-active {
//     background-color:transparent;
//     color: $navbar-item-hover-color; } }

a.navbar-item,
  .navbar-link {
	// &.is-active {
	//   color: $navbar-item-active-color; }
	&.is-active {
	  border-bottom: 1px solid $navbar-item-active-color; } }

.contentarticle {
  margin-top: 40px;
  margin-bottom: 60px;
}

#counters {
  padding: 0.75rem;
}

// .navbar-burger {
// 	margin-right: auto;
// 	margin-left: auto;
// }

//DESCTOP
@media only screen and (min-width:641px) {
    
    .introtext {
        margin-top: 8rem !important;
    }

    .main-cards {
        margin-top: 7rem !important;    
    }

    .footer-client {
        width: 280px
    }
    .childfigure{
        display: inline-block;
        // background:blue;
        margin:10px 0 0 2%;
        flex-grow: 1;
        // height:100px;
        height:100%;
        // width: calc(100% * (1/4) - 10px - 1px);
        width: calc(100% * (1/4));
        // width: 100%;
    }
    .main-bg-img {
        height: 685px;
    }
    .main-content {
        margin-top: 10rem !important;
    }

    .intro-buttons {
        margin-top: 2rem !important;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    
    .intro-buttons .button {
        padding-left: 1.375em;
        padding-right: 1.375em;
    }
    
    .contentarticle {
        padding-left: 1.5rem;
    }
    
    .contactsmap {
        width: auto;
        height: 640px;
    }
}

//MOBILE
@media only screen and (max-width: 640px) {
    
    .introtext {
        margin-top: 5rem !important;
    }
    
    .navbar-burger {
        color: #4a4a4a !important;
    }
    .is-fixed-top-mobile {
        top: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 30;
        // background-color: $primary;
        // color: $white
    }
    .main-header {
        margin-top: 3rem; 
    }
    .main-content {
        margin-top: 4rem !important;
    }

    .intro-buttons .button.is-light {
        margin-top: .5rem;
    }

    .main-cards {
        margin-top: 1rem !important;    
    }

    //bulma bug fix:
    .columns {
        margin-left: 0;
        margin-right: 0;
    }

    .columns:last-child {
        margin-bottom: 1.5rem;
    }

    .subtitle.is-3 {
        font-size: 1.5rem;
    }
    .tile.is-parent {
        padding: 0;
    }
    .contactsmap {
        width: auto;
        height: 300px;
    }
}
  

  