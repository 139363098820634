@charset "utf-8";

// 1. Import the initial variables
//@import "/node_modules/bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/initial-variables";

// 2. Colors
$blue: #3697d9;
//$black: #24292e;
//$black-invert: #fff;

// Update the sans-serif font family
//$family-sans-serif: "Helvetica", "Arial", sans-serif;
//$family-sans-serif: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;

// 3. Set the derived variables
$primary: $blue;
//$footer-background-color: $blue;
$dark_my: #373d44;
$footer-background-color: $dark_my;


//Меню слева продукция:
@import "bulma/sass/utilities/_all";
$menu-item-hover-color: $info;
$menu-item-active-background-color: $info;//$info;


//Главное меню:
$navbar-item-active-color: $blue;
$radius: 0;

// 4. Import the rest of Bulma
//@import "/node_modules/bulma/bulma";
@import "bulma/bulma";

//bulma carousel
// @import "bulma-carousel";

//custom spacing
@import "spacing";

@import "mystyles";




